<template>
  <div>
    <PreLoader v-if="isLoading" />

    <div
      v-for="(employeeSalaryData, index) in employeesSalaryData"
      :key="index"
      class="row card-container"
    >
      <div class="col-lg-3 col-md-6 image-container">
        <img
          :src="`${baseUrl}${employeeSalaryData.employeeImagePath}`"
          :onerror="`this.src='${defaultImg}'`"
          alt="Image"
          class="client-img"
        />
        <div class="actions">
          <ul>
            <li>
              <!-- :disabled="SalaryInquiry.totalRemaining == 0" -->
              <button
                v-b-modal.EmployeesEnquirySalariesPaidAdd
                class="btn p-0"
                :title="$t('newPayment')"
                @click="newSalaryPayment(employeeSalaryData)"
              >
                <img src="@/assets/images/pay.svg" class="icon-lg" />
              </button>
            </li>
            <li>
              <router-link
                :to="{
                  name: 'EmployeesEnquirySalariesPaids',
                  params: {
                    salaryInquiryData: employeeSalaryData,
                  },
                }"
                :title="$t('EmployeesEnquirySalariesPaids.modelName')"
              >
                <img src="@/assets/images/paids.svg" class="icon-lg" />
              </router-link>
            </li>
            <li>
              <button
                v-b-modal.SalaryInquiryInfo
                class="btn p-0"
                :title="$t('SalaryInquiries.workTimesDetails')"
                @click="setEmployeesSalary(employeeSalaryData)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="row">
          <DataLabelGroup
            :className="'col-lg-12 no-margin'"
            :value="employeeSalaryData.employeeCode"
            :title="$t('Employee.code')"
            :imgName="'number.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-12 no-margin'"
            :value="employeeSalaryData.employeeNameCurrent"
            :title="$t('Employee.modelName')"
            :imgName="'man.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-12 no-margin'"
            :value="employeeSalaryData.employeePhoneWithCC"
            :title="$t('phoneNumber')"
            :imgName="'phone.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-12 no-margin'"
            :value="employeeSalaryData.employeeEmail"
            :title="$t('email')"
            :imgName="'email.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-12 no-margin'"
            :value="employeeSalaryData.employeeJobNameCurrent"
            :title="$t('Employee.jobInContract')"
            :imgName="'jobs.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-12 no-margin'"
            :value="employeeSalaryData.totalSalary"
            :title="$t('SalaryInquiries.totalSalary')"
            :imgName="'money.svg'"
          />
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="row">
          <DataLabelGroup
            :className="'col-lg-12 no-margin'"
            :value="employeeSalaryData.totalAllowancesMonth"
            :title="$t('SalaryInquiries.totalAllowancesMonth')"
            :imgName="'money.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-12 no-margin'"
            :value="employeeSalaryData.totalPaid"
            :title="$t('SalaryInquiries.totalPaid')"
            :imgName="'money.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-12 no-margin'"
            :value="employeeSalaryData.totalRemaining"
            :title="$t('SalaryInquiries.totalRemaining')"
            :imgName="'money.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-12 no-margin'"
            :value="employeeSalaryData.totalWorkTimesPrice"
            :title="$t('SalaryInquiries.totalWorkTimesPrice')"
            :imgName="'money.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-12 no-margin'"
            :value="employeeSalaryData.insuranceStatusNameCurrent"
            :title="$t('SalaryInquiries.insuranceStatus')"
            :imgName="'money.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-12 no-margin'"
            :value="employeeSalaryData.totalInsuranceAmount"
            :title="$t('SalaryInquiries.totalInsuranceAmount')"
            :imgName="'money.svg'"
          />
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="row">
          <DataLabelGroup
            :className="'col-lg-12 no-margin'"
            :value="employeeSalaryData.taxStatusNameCurrent"
            :title="$t('SalaryInquiries.taxStatus')"
            :imgName="'money.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-12 no-margin'"
            :value="employeeSalaryData.totalTaxAmount"
            :title="$t('SalaryInquiries.totalTaxAmount')"
            :imgName="'money.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-12 no-margin'"
            :value="employeeSalaryData.totalIncreaseGrant"
            :title="$t('SalaryInquiries.totalIncreaseGrant')"
            :imgName="'money.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-12 no-margin'"
            :value="employeeSalaryData.totalDutactionGrant"
            :title="$t('SalaryInquiries.totalDutactionGrant')"
            :imgName="'money.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-12 no-margin'"
            :value="employeeSalaryData.totalKilometer"
            :title="$t('SalaryInquiries.totalKilometer')"
            :imgName="'money.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-12 no-margin'"
            :value="employeeSalaryData.totalPriceKilometer"
            :title="$t('SalaryInquiries.totalPriceKilometer')"
            :imgName="'money.svg'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { RESERVATION_STATUS } from "@/utils/constantLists";
import {
  setDataMultiLang,
  timeToLang,
  isDataExist,
  formateDateTimeLang,
} from "@/utils/functions";
import { BASE_URL } from "@/utils/constants";
import DEFAULT_IMG from "@/assets/images/man.svg";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "SalaryInquiriesCards",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    DataLabelGroup,
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      reservationStatus: RESERVATION_STATUS,
      defaultImg: DEFAULT_IMG,
      baseUrl: BASE_URL,
    };
  },
  props: ["employeesSalaryData"],
  methods: {
    newSalaryPayment(SalaryInquiry) {
      var data = {
        enquirySalarieToken: SalaryInquiry.enquirySalarieToken,
        enquirySalariePaidValue: SalaryInquiry.totalRemaining,
      };
      this.$emit("newSalaryPayment", data);
    },
    setEmployeesSalary(data) {
      this.$emit("setEmployeesSalary", data);
    },
    isDataExist,
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn, dataUnd) {
      return setDataMultiLang(lang, dataAr, dataEn, dataUnd);
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
