<template>
  <div class="">
    <form autocomplete="off" method="post" @submit.prevent="search()">
      <div class="my-card">
        <div class="row">
          <CustomInputInt
            :className="'col-md-5'"
            :id="'month'"
            :value="filterData.month"
            :title="$t('month')"
            :imgName="'date.svg'"
            :maxlength="2"
            v-on:changeValue="filterData.month = $event"
          />
          <CustomInputInt
            :className="'col-md-5'"
            :id="'year'"
            :value="filterData.year"
            :title="$t('year')"
            :imgName="'year.svg'"
            :maxlength="4"
            v-on:changeValue="filterData.year = $event"
          />

          <button
            name="submit"
            type="submit"
            class="btn btn-submit btn-search col-md-2"
          >
            {{ $t("search") }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { checkPrivilege } from "@/utils/functions";
import CustomInputInt from "@/components/general/CustomInputInt.vue";
import privilegeMixin from "@/utils/privilege-mixin";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [privilegeMixin, createToastMixin],
  props: {
    theFilterData: {
      type: Object,
    },
  },
  data() {
    return {
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      filterData: this.theFilterData,
    };
  },
  components: {
    CustomInputInt,
  },
  methods: {
    search() {
      this.$emit("search", this.filterData);
    },

    checkPrivilege,
  },
  async created() {},
};
</script>
