<template>
  <div :class="className">
    <div class="statistics-new-card">
      <div class="statistics-new-card-content">
        <h2 class="statistics-new-card-number cut-1line">{{ totalNumber }}</h2>
      </div>
      <div class="statistics-new-card-icon">
        <img :src="require('@/assets/images/' + icon)" />
      </div>
      <div class="statistics-new-card-bar">
        <h3 class="statistics-new-card-title cut-1line">
          {{ title || $t("notFound") }}
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatisticsCard",
  props: {
    className: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "number.svg",
    },
    title: {
      type: String,
      default: "",
    },
    totalNumber: {
      type: Number,
      default: 0,
    },
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
  async created() {},
};
</script>

<style lang="scss"></style>
