<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <div
      v-if="
        salaryInquiries.employeesSalaryData != undefined &&
        salaryInquiries.employeesSalaryData.length != 0
      "
    >
      <template v-if="filterAllEmployeesStatisticsSalary">
        <b-button v-b-toggle.statistics class="btn btn-lg btn-collapse">
          {{ $t("SalaryInquiries.statistics.modelName") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </b-button>
        <b-collapse id="statistics">
          <div class="row">
            <div
              v-for="(statistic, index) in filterAllEmployeesStatisticsSalary"
              :key="index"
              class="col-md-3"
            >
              <StatisticsCard
                :title="statistic.title"
                :totalNumber="statistic.value"
                :icon="statistic.icon"
              />
            </div>
          </div>
        </b-collapse>
      </template>

      <SalaryInquiryFilter
        :theFilterData="salaryInquiries.filterData"
        v-on:search="search($event)"
      />
      <div class="text-align-end">
        <div class="p-2">
          <button
            type="button"
            class="btn btn-outline-info"
            @click="toggelShowAsTable()"
          >
            {{ $t("SalaryInquiries.showAs") }}
            <i v-if="showAsTable" class="fa fa-id-card" aria-hidden="true"></i>
            <i v-else class="fa fa-table" aria-hidden="true"></i>
          </button>
        </div>
      </div>

      <SalaryInquiriesTable
        v-show="showAsTable"
        :employeesSalaryData="salaryInquiries.employeesSalaryData"
        v-on:setEmployeesSalary="employeesSalary.fillData($event)"
        v-on:newSalaryPayment="newSalaryPayment($event)"
        :pagination="salaryInquiries.pagination"
      />
      <SalaryInquiriesCards
        v-show="!showAsTable"
        :employeesSalaryData="salaryInquiries.employeesSalaryData"
        v-on:setEmployeesSalary="employeesSalary.fillData($event)"
        v-on:newSalaryPayment="newSalaryPayment($event)"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="salaryInquiries.pagination"
        v-on:changePage="changePage"
      />
      <SalaryInquiryInfo :employeesSalary="employeesSalary" />
      <!-- <SalaryInquiryCancel
        :salaryInquiry="salaryInquiries.salaryInquiry"
        v-on:refresh="getEmployeesStatisticsSalary()"
      /> -->
      <!-- <SalaryInquirySettlement
        :salaryInquiry="salaryInquiries.salaryInquiry"
        v-on:refresh="getEmployeesStatisticsSalary()"
      /> -->
    </div>

    <SalaryInquiryAddBtn />

    <EmployeesEnquirySalariesPaidAdd
      :salaryPaidData="
        employeesEnquirySalariesPaids.employeesEnquirySalariesPaid
      "
      v-on:refresh="getEmployeesStatisticsSalary()"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import SalaryInquiriesTable from "@/components/finance/salaryInquiries/SalaryInquiriesTable.vue";
import SalaryInquiriesCards from "@/components/finance/salaryInquiries/SalaryInquiriesCards.vue";
import SalaryInquiryInfo from "@/components/finance/salaryInquiries/SalaryInquiryInfo.vue";
// import SalaryInquiryCancel from "@/components/finance/salaryInquiries/SalaryInquiryCancel.vue";
// import SalaryInquirySettlement from "@/components/finance/salaryInquiries/SalaryInquirySettlement.vue";
import SalaryInquiryAddBtn from "@/components/finance/salaryInquiries/SalaryInquiryAddBtn.vue";
import SalaryInquiryFilter from "@/components/finance/salaryInquiries/SalaryInquiryFilter.vue";
import Pagination from "@/components/general/Pagination.vue";
import SalaryInquiries from "@/models/finance/salaryInquiries/SalaryInquiries";
import EmployeesSalary from "@/models/finance/salaryInquiries/EmployeesSalary";
import createToastMixin from "@/utils/create-toast-mixin";
import { getCurrentFullDate } from "@/utils/functions";
import StatisticsCard from "@/components/general/StatisticsCard.vue";
import EmployeesEnquirySalariesPaids from "@/models/finance/salaryInquiries/employeesEnquirySalariesPaids/EmployeesEnquirySalariesPaids";
import EmployeesEnquirySalariesPaidAdd from "@/components/finance/salaryInquiries/employeesEnquirySalariesPaids/EmployeesEnquirySalariesPaidAdd.vue";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    SalaryInquiriesTable,
    SalaryInquiriesCards,
    SalaryInquiryInfo,
    // SalaryInquiryCancel,
    // SalaryInquirySettlement,
    SalaryInquiryAddBtn,
    SalaryInquiryFilter,
    Pagination,
    StatisticsCard,
    EmployeesEnquirySalariesPaidAdd,
  },
  data() {
    return {
      isLoading: true,
      showAsTable: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      salaryInquiries: new SalaryInquiries(),
      employeesSalary: new EmployeesSalary(),
      filterAllEmployeesStatisticsSalary: [],
      employeesEnquirySalariesPaids: new EmployeesEnquirySalariesPaids(),
    };
  },
  methods: {
    getCurrentFullDate,
    changePage(page) {
      this.salaryInquiries.pagination.selfPage = page;
      this.getEmployeesStatisticsSalary();
    },
    newSalaryPayment(data) {
      this.employeesEnquirySalariesPaids.employeesEnquirySalariesPaid.enquirySalarieToken =
        data.enquirySalarieToken;
      this.employeesEnquirySalariesPaids.employeesEnquirySalariesPaid.enquirySalariePaidValue =
        data.enquirySalariePaidValue;
    },

    setFilteredStatistics() {
      let statistic = this.salaryInquiries.allEmployeesStatisticsSalary;

      this.filterAllEmployeesStatisticsSalary = [
        {
          title: this.$t("SalaryInquiries.statistics.totalAbsenceTimePrice"),
          value: statistic.totalAbsenceTimePrice,
          icon: "money.svg",
        },
        {
          title: this.$t("SalaryInquiries.statistics.totalAllowance"),
          value: statistic.totalAllowance,
          icon: "allowances.svg",
        },
        {
          title: this.$t("SalaryInquiries.statistics.totalAllowancesMonth"),
          value: statistic.totalAllowancesMonth,
          icon: "allowances.svg",
        },
        {
          title: this.$t("SalaryInquiries.statistics.totalDutactionGrant"),
          value: statistic.totalDutactionGrant,
          icon: "grants.svg",
        },
        {
          title: this.$t(
            "SalaryInquiries.statistics.totalEarlyDepartureTimePrice"
          ),
          value: statistic.totalEarlyDepartureTimePrice,
          icon: "money.svg",
        },
        {
          title: this.$t("SalaryInquiries.statistics.totalIncreaseGrant"),
          value: statistic.totalIncreaseGrant,
          icon: "grants.svg",
        },
        {
          title: this.$t("SalaryInquiries.statistics.totalInsuranceAmount"),
          value: statistic.totalInsuranceAmount,
          icon: "insurances.svg",
        },
        {
          title: this.$t("SalaryInquiries.statistics.totalKilometer"),
          value: statistic.totalKilometer,
          icon: "kilometer.svg",
        },
        {
          title: this.$t(
            "SalaryInquiries.statistics.totalLateAttendaceTimePrice"
          ),
          value: statistic.totalLateAttendaceTimePrice,
          icon: "money.svg",
        },
        {
          title: this.$t(
            "SalaryInquiries.statistics.totalOfficialHolidayTimePrice"
          ),
          value: statistic.totalOfficialHolidayTimePrice,
          icon: "money.svg",
        },
        {
          title: this.$t("SalaryInquiries.statistics.totalOverTimePrice"),
          value: statistic.totalOverTimePrice,
          icon: "money.svg",
        },
        {
          title: this.$t("SalaryInquiries.statistics.totalPaid"),
          value: statistic.totalPaid,
          icon: "money.svg",
        },
        {
          title: this.$t("SalaryInquiries.statistics.totalPriceKilometer"),
          value: statistic.totalPriceKilometer,
          icon: "money.svg",
        },
        {
          title: this.$t("SalaryInquiries.statistics.totalRemaining"),
          value: statistic.totalRemaining,
          icon: "money.svg",
        },
        {
          title: this.$t("SalaryInquiries.statistics.totalSalary"),
          value: statistic.totalSalary,
          icon: "money.svg",
        },
        {
          title: this.$t("SalaryInquiries.statistics.totalTaxAmount"),
          value: statistic.totalTaxAmount,
          icon: "money.svg",
        },
        {
          title: this.$t(
            "SalaryInquiries.statistics.totalUnknownWorkingTimePrice"
          ),
          value: statistic.totalUnknownWorkingTimePrice,
          icon: "money.svg",
        },
        {
          title: this.$t("SalaryInquiries.statistics.totalWorkTimePrice"),
          value: statistic.totalWorkTimePrice,
          icon: "money.svg",
        },
        {
          title: this.$t("SalaryInquiries.statistics.totalWorkTimesPrice"),
          value: statistic.totalWorkTimesPrice,
          icon: "money.svg",
        },
      ];
    },
    toggelShowAsTable() {
      this.showAsTable = !this.showAsTable;
    },
    search(data) {
      this.salaryInquiries.filterData.fillData(data);
      this.salaryInquiries.employeesSalaryData = [];
      this.getEmployeesStatisticsSalary();
    },
    async getEmployeesStatisticsSalary() {
      this.isLoading = true;
      try {
        const response =
          await this.salaryInquiries.salaryInquiry.getEmployeesStatisticsSalary(
            this.language,
            this.userAuthorizeToken,
            this.salaryInquiries.pagination,
            this.salaryInquiries.filterData
          );

        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.salaryInquiries.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.salaryInquiries.employeesSalaryData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.salaryInquiries.employeesSalaryData = [];
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.salaryInquiries.employeesSalaryData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.salaryInquiries.employeesSalaryData = [];
        this.exceptionImg = "illustrator-somethingWrong.svg";
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.salaryInquiries.filterData.month = this.getCurrentFullDate().month;
    this.salaryInquiries.filterData.year = this.getCurrentFullDate().year;
    await this.getEmployeesStatisticsSalary();
    this.setFilteredStatistics();
  },
};
</script>
