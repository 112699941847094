<template>
  <div class="fab-container">
    <div class="fab-parent fab-icon-holder">
      <i class="fa fa-chevron-up"></i>
    </div>
    <ul class="fab-options">
      <li>
        <span class="fab-label">
          <router-link :to="{ name: 'SalaryInquiryAdd' }" class="">
            {{ $t("SalaryInquiries.add") }}
          </router-link>
        </span>
        <div class="fab-icon-holder">
          <router-link :to="{ name: 'SalaryInquiryAdd' }" class="">
            <img src="@/assets/images/plus.svg" />
          </router-link>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { checkPrivilege } from "@/utils/functions";
// import CustomInput from "@/components/general/CustomInput.vue";

import privilegeMixin from "@/utils/privilege-mixin";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [privilegeMixin, createToastMixin],

  data() {
    return {};
  },
  components: {
    // CustomInput,
  },
  methods: {
    checkPrivilege,
  },
  async created() {},
};
</script>
