export default class WorkTimesDetails {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.totalAllowance = "";
    this.totalWorkTimeDuration = "";
    this.totalWorkTimePrice = "";
    this.totalLateAttendaceTimeDuration = "";
    this.totalLateAttendaceTimePrice = "";
    this.totalEarlyDepartureTimeDuration = "";
    this.totalEarlyDepartureTimePrice = "";
    this.totalLostTimeDuration = "";
    this.totalLostTimePrice = "";
    this.totalOverTimeDuration = "";
    this.totalOverTimePrice = "";
    this.totalOfficialHolidayTimeDuration = "";
    this.totalOfficialHolidayTimePrice = "";
    this.totalAbsenceTimeDuration = "";
    this.totalAbsenceTimePrice = "";
    this.totalUnknownWorkingTimeDuration = "";
    this.totalUnknownWorkingTimePrice = "";
  }
  fillData(data) {
    this.totalAllowance = data.totalAllowance;
    this.totalWorkTimeDuration = data.totalWorkTimeDuration;
    this.totalWorkTimePrice = data.totalWorkTimePrice;
    this.totalLateAttendaceTimeDuration = data.totalLateAttendaceTimeDuration;
    this.totalLateAttendaceTimePrice = data.totalLateAttendaceTimePrice;
    this.totalEarlyDepartureTimeDuration = data.totalEarlyDepartureTimeDuration;
    this.totalEarlyDepartureTimePrice = data.totalEarlyDepartureTimePrice;
    this.totalLostTimeDuration = data.totalLostTimeDuration;
    this.totalLostTimePrice = data.totalLostTimePrice;
    this.totalOverTimeDuration = data.totalOverTimeDuration;
    this.totalOverTimePrice = data.totalOverTimePrice;
    this.totalOfficialHolidayTimeDuration =
      data.totalOfficialHolidayTimeDuration;
    this.totalOfficialHolidayTimePrice = data.totalOfficialHolidayTimePrice;
    this.totalAbsenceTimeDuration = data.totalAbsenceTimeDuration;
    this.totalAbsenceTimePrice = data.totalAbsenceTimePrice;
    this.totalUnknownWorkingTimeDuration = data.totalUnknownWorkingTimeDuration;
    this.totalUnknownWorkingTimePrice = data.totalUnknownWorkingTimePrice;
  }
}
