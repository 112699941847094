<template>
  <b-modal id="SalaryInquiryInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/SalaryInquiries.svg" class="icon-lg" />
        {{ $t("SalaryInquiries.workTimesDetails") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(employeesSalary.totalWorkTimesPrice)"
        :title="$t('SalaryInquiries.totalWorkTimesPrice')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(workTimesDetails.totalAllowance)"
        :title="$t('SalaryInquiries.totalAllowance')"
        :imgName="'money.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="workTimesDetails.totalWorkTimeDuration"
        :title="$t('SalaryInquiries.totalWorkTimeDuration')"
        :imgName="'dateAndTime.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(workTimesDetails.totalWorkTimePrice)"
        :title="$t('SalaryInquiries.totalWorkTimePrice')"
        :imgName="'money.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="workTimesDetails.totalLateAttendaceTimeDuration"
        :title="$t('SalaryInquiries.totalLateAttendaceTimeDuration')"
        :imgName="'dateAndTime.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(workTimesDetails.totalLateAttendaceTimePrice)"
        :title="$t('SalaryInquiries.totalLateAttendaceTimePrice')"
        :imgName="'money.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="workTimesDetails.totalEarlyDepartureTimeDuration"
        :title="$t('SalaryInquiries.totalEarlyDepartureTimeDuration')"
        :imgName="'dateAndTime.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(workTimesDetails.totalEarlyDepartureTimePrice)"
        :title="$t('SalaryInquiries.totalEarlyDepartureTimePrice')"
        :imgName="'money.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="workTimesDetails.totalLostTimeDuration"
        :title="$t('SalaryInquiries.totalLostTimeDuration')"
        :imgName="'dateAndTime.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(workTimesDetails.totalLostTimePrice)"
        :title="$t('SalaryInquiries.totalLostTimePrice')"
        :imgName="'money.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="workTimesDetails.totalOverTimeDuration"
        :title="$t('SalaryInquiries.totalOverTimeDuration')"
        :imgName="'dateAndTime.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(workTimesDetails.totalOverTimePrice)"
        :title="$t('SalaryInquiries.totalOverTimePrice')"
        :imgName="'money.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="workTimesDetails.totalOfficialHolidayTimeDuration"
        :title="$t('SalaryInquiries.totalOfficialHolidayTimeDuration')"
        :imgName="'dateAndTime.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(workTimesDetails.totalOfficialHolidayTimePrice)"
        :title="$t('SalaryInquiries.totalOfficialHolidayTimePrice')"
        :imgName="'money.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="workTimesDetails.totalAbsenceTimeDuration"
        :title="$t('SalaryInquiries.totalAbsenceTimeDuration')"
        :imgName="'dateAndTime.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(workTimesDetails.totalAbsenceTimePrice)"
        :title="$t('SalaryInquiries.totalAbsenceTimePrice')"
        :imgName="'money.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="workTimesDetails.totalUnknownWorkingTimeDuration"
        :title="$t('SalaryInquiries.totalUnknownWorkingTimeDuration')"
        :imgName="'dateAndTime.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(workTimesDetails.totalUnknownWorkingTimePrice)"
        :title="$t('SalaryInquiries.totalUnknownWorkingTimePrice')"
        :imgName="'money.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import { moneyFormat } from "@/utils/functions";
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";

export default {
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      workTimesDetails: this.employeesSalary.workTimesDetails,
    };
  },
  props: ["employeesSalary"],
  methods: {
    moneyFormat,
  },
};
</script>
